<template>
  <div>
    <div :class="status.startsWith('error') ? 'status error' : 'status ok'">
      {{ status }}
    </div>

    <!-- Options -->
    <div class="options">
      <div>
        <md-button class=" md-raised md-primary"
                   @click="selectedActivity = ''; selectedChallenge = ''; addChallenge = true;">
          add Challenge
        </md-button>
        <md-button class=" md-raised" @click="addActivity()" v-if="selectedChallenge">
          add Activity
        </md-button>
      </div>
      <div class="flexContent">
        <md-field>
          <label for="challenge">Challenge</label>
          <md-select v-model="selectedChallenge" name="challenge" id="challenge"
                     @md-selected="addChallenge = false; challengeSelected()">
            <md-option
                v-for="a in challenges"
                v-bind:key="a._id"
                :value="a._id">
              {{ a.title }}
            </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="section">Activity</label>
          <md-select v-model="selectedActivity" name="section" id="section"
                     @md-selected="addChallenge = false; checkCreateActivity()">
            <md-option
                v-for="s in activityOptions"
                v-bind:key="s._id"
                :value="s._id">
              {{ s._id === "add" ? s.nr : `#${s.nr}` }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>

    <!-- Add Challenge -->
    <transition name="fade">
      <div class="editSection" v-if="addChallenge">
        <h2 class="md-title">Add Challenge</h2>
        <md-autocomplete v-model="newChallenge.image" :md-options="files">
          <label>Image</label>
        </md-autocomplete>
        <md-field>
          <label>Title</label>
          <md-input v-model="newChallenge.title"></md-input>
        </md-field>
        <md-field>
          <label>Text</label>
          <md-textarea v-model="newChallenge.text" md-autogrow></md-textarea>
        </md-field>
        <md-autocomplete v-model="newChallenge.author" :md-options="authors.map(e => e.name)">
          <label>Author</label>
        </md-autocomplete>
        <div class="error">{{ authorError }}</div>

        <div class="error">{{ addChallengeError }}</div>
        <div class="actions">
          <md-button class="" @click="addChallenge = false">Close</md-button>
          <md-button class="md-primary" @click="saveChallenge()">Save</md-button>
        </div>
      </div>
    </transition>

    <!-- Edit Activity -->
    <transition name="fade">
      <div class="editSection" v-if="activeActivity">
        <h2 class="md-title">Edit Section</h2>

        <md-field>
          <label>Activity Number</label>
          <md-input
              v-model="activeActivity.nr"
              type="number"
              @input="saveChanges(1, 'nr', activeActivity.nr)">
          </md-input>
        </md-field>
        <md-field>
          <label>Title</label>
          <md-input
              v-model="activeActivity.title"
              @input="saveChanges(1, 'title', activeActivity.title)">
          </md-input>
        </md-field>
        <md-field>
          <label>Text (Supports Markdown)</label>
          <md-textarea
              v-model="activeActivity.text"
              @input="saveChanges(1, 'text', activeActivity.text)" md-autogrow>
          </md-textarea>
        </md-field>
        <div class="actions">
          <md-button class="md-raised md-accent" @click="deleteActivity()">Delete</md-button>
        </div>
      </div>
    </transition>

    <!-- Edit Challenge -->
    <transition name="fade">
      <div class="editSection" v-if="activeChallenge && !activeActivity">
        <h2 class="md-title">Edit Challenge</h2>

        <md-autocomplete
            v-model="activeChallenge.image"
            :md-options="files"
            @input="saveChanges(0, 'image', activeChallenge.image)">
          <label>Image</label>
        </md-autocomplete>
        <md-field>
          <label>Title</label>
          <md-input
              v-model="activeChallenge.title"
              @change="saveChanges(0, 'title', activeChallenge.title)">
          </md-input>
        </md-field>
        <md-field>
          <label>Text</label>
          <md-textarea
              v-model="activeChallenge.text"
              @change="saveChanges(0, 'text', activeChallenge.text)" md-autogrow>
          </md-textarea>
        </md-field>
        <!--        <md-autocomplete-->
        <!--          v-if="expertMode"-->
        <!--          v-model="activeChallenge.author"-->
        <!--          :md-options="authors.map(e => e.name)"-->
        <!--          @md-closed="saveChanges(0, 'author', authors.find(e => e.name === activeChallenge.author))">-->
        <!--          <label>Author</label>-->
        <!--        </md-autocomplete>-->
        <!--        <md-field v-if="expertMode">-->
        <!--          <label>Tags (Separated by ",")</label>-->
        <!--          <md-input-->
        <!--            v-model="activeChallenge.tags"-->
        <!--            @change="saveChanges(0, 'tags', activeChallenge.tags.split(','))">-->
        <!--          </md-input>-->
        <!--        </md-field>-->
        <!--        <md-field v-if="expertMode">-->
        <!--          <label>Main Points (Separated by ",")</label>-->
        <!--          <md-textarea-->
        <!--            v-model="activeChallenge.mainPoints"-->
        <!--            @change="saveChanges(0, 'mainPoints', activeChallenge.mainPoints.split(','))"-->
        <!--            md-autogrow></md-textarea>-->
        <!--        </md-field>-->
        <div class="actions">
          <!--          <md-switch v-model="expertMode" class="md-primary">Expert Mode</md-switch>-->
          <md-switch v-model="preview" class="md-primary">Show Preview</md-switch>
          <md-switch class="md-raised md-primary" v-model="activeChallenge.published" @change="publishChallenge()">
            Published
          </md-switch>
          <md-button class="md-raised md-accent" @click="deleteChallenge()">Delete</md-button>

        </div>

        <div class="editSection" v-if="preview">
          <vue-markdown> Preview is not supported - I am not even getting paid for that so give me time please - thanks :)</vue-markdown>
<!--          <div class="md-title">{{ activeChallenge.title }}</div>-->
<!--          <img v-if="activeChallenge.image" :src="activeChallenge.image" :alt="activeChallenge.title"-->
<!--               style="max-width:500px"/>-->
<!--          <div class="md-subheading">Author: {{ activeChallenge.author.name }}</div>-->

<!--          <div class="md-title"><vue-markdown>{{ activeChallenge.text }}</vue-markdown></div>-->

<!--          <div v-for="s in activeChallenge.content" v-bind:key="s._id" style="margin-top: 10px">-->
<!--            <div class="md-body-2" v-if="s.title">{{ s.title }}</div>-->
<!--            <div v-if="s.text">-->
<!--              <vue-markdown>{{ s.text }}</vue-markdown>-->
<!--            </div>-->
<!--            <img v-if="s.image" :src="s.image" :alt="s.title" style="max-width:500px"/>-->
<!--          </div>-->
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import VueMarkdown from '@adapttive/vue-markdown'
import {axiosDelete, axiosGet, axiosPatch, axiosPost} from "@/utils/axiosWrapper";
import {FILES_LOCATION} from "@/config";

export default {
  name: "Articles",
  data() {
    return {
      expertMode: false,
      preview: true,

      addChallenge: false,
      challenges: [],
      newChallenge: {},

      selectedChallenge: "",
      activeChallenge: null,
      selectedActivity: "",
      activeActivity: null,
      activityOptions: [],

      files: [],
      authors: [],

      status: "",
      authorError: "",
      addChallengeError: ""
    }
  },
  methods: {
    load: function () {
      // Loading Articles
      this.status = "loading data..."
      this.loadChallenges().then((a) => {
        //console.log("Articles:", a)
        this.challenges = a
        this.challenges = this.challenges.map(a => {
          a.activity = a.activity.sort((a, b) => a.nr - b.nr)
          return a
        })
        this.status = "all set"
      }).catch((e) => {
        console.warn(e)
        this.status = "error: loading challenges"
      })
      // Loading Files
      this.loadFiles().then((a) => {
        // console.log("Files:", a)
        this.files = a
      }).catch((e) => {
        console.warn(e)
      })
      //Authors
      this.loadAuthors().then((a) => {
        // console.log("Authors:", a)
        this.authors = a
      }).catch((e) => {
        console.warn(e)
      })
    },
    loadChallenges: async function () {
      return (await axiosGet("/selfSupport/challenge")).data
    },
    loadFiles: async function () {
      return (await axiosGet('/files')).data
          .map(e => FILES_LOCATION + e)
    },
    loadAuthors: async function () {
      return (await axiosGet('/team')).data
    },
    resetSite: function () {
      this.load()
      this.addChallengeError = ""
      this.authorError = ""
      this.addChallenge = false
      this.activityOptions = []
      this.selectedActivity = ""
      this.selectedChallenge = ""
      this.activeChallenge = null
      this.activeActivity = null
    },
    resetActivity: function () {
      this.activeActivity = null
      this.selectedActivity = ""
      this.status = "loading challenges..."
      this.loadChallenges().then((a) => {
        this.challenges = a.map(a => {
          a.activity = a.activity.sort((a, b) => a.nr - b.nr)
          return a
        })
        this.activityOptions = this.challenges.find(a => a._id === this.selectedChallenge).activity
        this.status = "all set"
      }).catch((e) => {
        console.warn(e)
        this.status = "error: loading challenges"
      })
    },
    deleteActivity: function () {
      this.status = "deleting section..."
      axiosDelete("/selfSupport/challenge/activity/" + this.selectedActivity)
          .then(() => {
            this.status = "all set"
            this.resetActivity()
          })
          .catch(e => {
            console.warn(e.response.data.error)
            this.status = "error: creating section... try again"
          })
    },
    deleteChallenge: function () {
      this.status = "deleting knowledge..."
      axiosDelete("/selfSupport/challenge/" + this.selectedChallenge)
          .then(() => {
            this.status = "all set"
            this.resetSite()
          })
          .catch(e => {
            console.warn(e.response.data.error)
            this.status = "error: creating section... try again"
          })
    },
    saveChallenge: async function () {
      this.addChallengeError = ""
      this.authorError = ""
      this.status = "saving..."

      let author = this.authors.filter(e => e.name === this.newChallenge.author)
      if (author.length !== 1) {
        this.authorError = "Author not found, please add any author first to team."
        return
      } else {
        this.newChallenge.author = author[0]
      }

      if (this.newChallenge.tags)
        this.newChallenge.tags = this.newChallenge.tags.split(", ")
      if (this.newChallenge.mainPoints)
        this.newChallenge.mainPoints = this.newChallenge.mainPoints.split("\n")
      if (this.isVideo)
        this.newChallenge.material = "Video"

      axiosPost("/selfSupport/challenge/new", this.newChallenge)
          .then(() => {
            this.resetSite()
          })
          .catch(e => {
            console.warn(e.response.data.error)
            this.addChallengeError = "Error saving the challenge... Try later again."
          }).finally(() => this.status = "all set")
    },
    publishChallenge: function () {
      this.status = "publishing..."
      axiosPatch("/selfSupport/challenge/publish",
          {published: this.activeChallenge.published, id: this.selectedChallenge})
          .then(() => this.status = "all set")
          .catch(e => {
            console.warn(e)
            this.status = "error: publishing, reload site and try again"
          })
    },
    addActivity: function () {
      this.status = "creating new section"
      const section = {
        nr: this.challenges.find(a => a._id === this.selectedChallenge).activity.length + 1
      }
      axiosPost("/selfSupport/challenge/activity/new", {
        challenge: this.selectedChallenge,
        activity: section
      })
          .then(() => {
            this.resetActivity()
            this.status = "all set"
          })
          .catch(e => {
            console.warn(e.response.data.error)
            this.status = "error: creating section... try again"
          })
    },
    saveChanges: function (kind, part, content) {
      this.status = "saving..."
      let api = ""
      switch (kind) {
        case 0:
          api = `/selfSupport/challenge/challenge/${this.selectedChallenge}/${part}`;
          break;
        case 1:
          api = `/selfSupport/challenge/activity/${this.selectedActivity}/${part}`;
          break;
      }
        axiosPatch(api, {toChange: content})
          .then(() => {
            this.status = "all set"
          })
          .catch(e => {
            console.warn(e.response)
            this.status = "error: saving changes - reload site"
          })
    },
    challengeSelected: function () {
      this.selectedActivity = ""
      this.activeActivity = null
      this.activityOptions = this.challenges.find(a => a._id === this.selectedChallenge).activity

      this.activeChallenge = this.challenges
          .find(a => a._id === this.selectedChallenge)
    },
    checkCreateActivity: function () {
      this.activeActivity = this.challenges
          .find(a => a._id === this.selectedChallenge).activity
          .find(s => s._id === this.selectedActivity)
    },
  },
  components: {
    VueMarkdown
  },
  beforeMount: function () {
    this.load()
  }
}
</script>
<style scoped>
.status {
  text-align: right;
  opacity: .8
}

.ok {
  color: #00695f;
}

.error {
  color: #ba000d
}

.editSection {
  margin-top: 2em;
}

.actions {
  text-align: right;
}

.options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flexContent {
  width: 800px;
  display: flex;
  flex-direction: row;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
